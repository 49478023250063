@font-face {
    font-display: swap;
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 300;
    src:
        url('../fonts/source-serif-pro-v15-latin-300.woff2') format('woff2'),
        url('../fonts/source-serif-pro-v15-latin-300.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'Source Serif Pro';
    font-style: italic;
    font-weight: 300;
    src:
        url('../fonts/source-serif-pro-v15-latin-300italic.woff2') format('woff2'),
        url('../fonts/source-serif-pro-v15-latin-300italic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 600;
    src:
        url('../fonts/source-serif-pro-v15-latin-600.woff2') format('woff2'),
        url('../fonts/source-serif-pro-v15-latin-600.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'Source Serif Pro';
    font-style: italic;
    font-weight: 600;
    src:
        url('../fonts/source-serif-pro-v15-latin-600italic.woff2') format('woff2'),
        url('../fonts/source-serif-pro-v15-latin-600italic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src:
        url('../fonts/montserrat-v25-latin-300.woff2') format('woff2'),
        url('../fonts/montserrat-v25-latin-300.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src:
        url('../fonts/montserrat-v25-latin-600.woff2') format('woff2'),
        url('../fonts/montserrat-v25-latin-600.woff') format('woff');
}
