.item-card {

}

.item-card__link {
    display: flex;
    flex-flow: column;

    text-decoration: none;

    @media (hover: hover) {
        transition: opacity 0.2s ease-in-out;

        &:hover,
        &:active,
        &:focus {
            opacity: 0.7;
        }
    }
}

.item-card__image-wrapper {
    aspect-ratio: 1;

    background: $lightestGray;

    border-radius: u(0.25);
}

.item-card__image,
.item-card__image > img {
    display: block;
    width: 100%;
    height: auto;

    border-radius: u(0.25);
}

.item-card__text-wrapper {
    margin-top: u(1);
    display: flex;
    flex-flow: row;
    gap: u(2);
    justify-content: space-between;
    align-items: flex-start;

    // stylelint-disable max-nesting-depth, selector-max-compound-selectors
    .no-flex-gap & > * + * {
        margin-left: u(2);
    }
    // stylelint-enable max-nesting-depth, selector-max-compound-selectors
}

.item-card__title {
    @extend %t-small-body;
}

.item-card__price {
    @extend %t-small-body;
}
