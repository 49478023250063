.page {
    @include grid;
    grid-template-areas:
        '#{str-repeat(2, 'main')}'
        '#{str-repeat(2, 'extra')}'
        '#{str-repeat(2, 'sidebar')}';
    row-gap: 0;

    @include media('>=tablet') {
        grid-template-areas:
            '#{str-repeat(4, 'main')}  . .'
            '#{str-repeat(5, 'extra')}   .'
            '#{str-repeat(5, 'sidebar')} .';
        row-gap: 0;
    }

    @include media('>=desktop') {
        grid-template-areas:
            '#{str-repeat(5, 'main')} . #{str-repeat(6, 'sidebar')}'
            '#{str-repeat(5, 'extra')} . #{str-repeat(6, 'sidebar')}';
        row-gap: 0;
    }
}

.page__main {
    grid-area: main;
}

.page__title {
    @extend %t-h1;
    margin-bottom: u(2);

    @include media('>=tablet') {
        margin-bottom: u(4);
    }
}

.page__body {
    @extend %t-body;
}

.page__sidebar {
    grid-area: sidebar;
    display: flex;
    flex-flow: column;
    gap: $gridGutterMobile;
    margin-top: $gridGutterMobile;

    // stylelint-disable max-nesting-depth, selector-max-compound-selectors
    .no-flex-gap & > * + * {
        margin-top: $gridGutterMobile;
    }
    // stylelint-enable max-nesting-depth, selector-max-compound-selectors

    @include media('>=tablet') {
        gap: $gridGutterTablet;
        margin-top: $gridGutterTablet;

        // stylelint-disable max-nesting-depth, selector-max-compound-selectors
        .no-flex-gap & > * + * {
            margin-top: $gridGutterTablet;
        }
        // stylelint-enable max-nesting-depth, selector-max-compound-selectors
    }

    @include media('>=desktop') {
        gap: $gridGutterDesktop;
        margin-top: 0;

        // stylelint-disable max-nesting-depth, selector-max-compound-selectors
        .no-flex-gap & > * + * {
            margin-top: $gridGutterDesktop;
        }
        // stylelint-enable max-nesting-depth, selector-max-compound-selectors
    }
}

.page__image,
.page__image > img {
    display: block;
    width: 100%;
    height: auto;

    border-radius: u(0.25);
}

.page__embed {
    position: relative;

    &::before {
        display: block;
        height: 0;
        padding-top: 100%;

        content: '';
    }

    iframe {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0 0 0 0;

        border-radius: u(0.25);
    }
}

.page__extra-content {
    grid-area: extra;

    &:empty {
        display: none;
    }
}

.page__form {
    margin: u(4) 0;
    padding-top: u(4);

    border-top: 1px solid $lighterGray;

    @include media('>=tablet') {
        margin: u(6) 0;
        padding-top: u(6);
    }
}
