// Colours ////////////////////////////////////////////////////////////////////
$black: #000;
$white: #fff;
$offWhite: #f5f5f5;
$darkGray: #313131;
$gray: #606060;
$lightGray: #8b8b8b;
$lighterGray: #dbdbdb;
$lightestGray: #efefef;
$red: #de1d1d;
$lightRed: #ffd3d5;
$green: #b2d9b8;

// Fonts //////////////////////////////////////////////////////////////////////
$fontStackSans: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$fontStackSerif: 'Source Serif Pro', Baskerville, Times, 'Times New Roman', serif;

// Units //////////////////////////////////////////////////////////////////////
$baseFontSize: 16px;
$baseLineHeightRatio: 1.2;

$baseFontSizeMobile: 12px;
$baseFontSizeTablet: 16px;

$fontWeightLight: 300;
$fontWeightSemiBold: 600;

// Layers /////////////////////////////////////////////////////////////////////
$z-header: 15;
$z-carousel: 5;
$z-messages: 10;
$z-toast: 10;

// Layout and include-media config ////////////////////////////////////////////
$pageGutterMobile: u(2);
$gridGutterMobile: u(2);
$gridColumnsMobile: 2;

$pageGutterTablet: u(4);
$gridGutterTablet: u(4);
$gridColumnsTablet: 6;

$pageGutterDesktop: u(8);
$gridGutterDesktop: u(4);
$gridColumnsDesktop: 12;

$layoutMaxWidth: 1900px;

$headerHeightMobile: u(5.5);
$headerHeightTablet: u(7.5);
$headerHeightDesktop: u(11);

$mobile: 320;
$mobilePx: $mobile * 1px;
$tablet: 700;
$tabletPx: $tablet * 1px;
$desktop: 1000;
$desktopPx: $desktop * 1px;
$largeDesktop: 1200;
$largeDesktopPx: $largeDesktop * 1px;

$breakpoints: (
    mobile: $mobilePx,
    tablet: $tabletPx,
    desktop: $desktopPx,
    largeDesktop: $largeDesktopPx,
);

// Exports
// stylelint-disable property-no-unknown, property-case
:export {
    mobile: $mobile;
    mobilePx: $mobilePx;
    tablet: $tablet;
    tabletPx: $tabletPx;
    desktop: $desktop;
    desktopPx: $desktopPx;
    largeDesktop: $largeDesktop;
    largeDesktopPx: $largeDesktopPx;
}
// stylelint-enable property-no-unknown, property-case
