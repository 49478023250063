@mixin hide-text-alt() {
    font: 0/0 a; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

@mixin clamp {
    margin-left: auto;
    margin-right: auto;
    max-width: $layoutMaxWidth;
}

@mixin gutter-padding($vertical: false) {
    @if $vertical {
        padding: $pageGutterMobile;
    } @else {
        padding-left: $pageGutterMobile;
        padding-right: $pageGutterMobile;
    }

    @include media('>=tablet') {
        @if $vertical {
            padding: $pageGutterTablet;
        } @else {
            padding-left: $pageGutterTablet;
            padding-right: $pageGutterTablet;
        }
    }

    @include media('>=desktop') {
        @if $vertical {
            padding: $pageGutterDesktop;
        } @else {
            padding-left: $pageGutterDesktop;
            padding-right: $pageGutterDesktop;
        }
    }
}

@mixin grid($cols: false) {
    $_gridColumnsMobile: if($cols, $cols, $gridColumnsMobile);
    $_gridColumnsTablet: if($cols, $cols, $gridColumnsTablet);
    $_gridColumnsDesktop: if($cols, $cols, $gridColumnsDesktop);

    display: grid;
    grid-template-columns: repeat($_gridColumnsMobile, 1fr);
    gap: $gridGutterMobile;

    @include media('>=tablet') {
        grid-template-columns: repeat($_gridColumnsTablet, 1fr);
        gap: $gridGutterTablet;
    }

    @include media('>=desktop') {
        grid-template-columns: repeat($_gridColumnsDesktop, 1fr);
        gap: $gridGutterDesktop;
    }
}

@mixin nav-links {
    display: block;
    padding: u(0.5) 0;

    text-decoration: none;

    &[disabled] {
        opacity: 0.5;
        pointer-events: none;
    }

    &[aria-current='true'] {
        font-weight: $fontWeightSemiBold;
    }

    @media (hover: hover) {
        &:hover,
        &:active,
        &:focus {
            text-decoration: underline;
        }
    }
}

@mixin text-links {
    text-decoration-line: underline;

    @media (hover: hover) {
        text-decoration-line: none;

        &:hover,
        &:active,
        &:focus {
            text-decoration-line: underline;
        }
    }
}

@mixin text-links-alt {
    text-decoration-line: underline;
    transition: all 0.2s ease-in-out;

    &:hover,
    &:focus-visible {
        opacity: 0.7;
    }
}

@mixin visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
