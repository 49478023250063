.button {
    @extend %t-button;
    display: block;
    padding: u(1.25);

    background: $gray;

    border-radius: u(0.25);

    text-align: center;
    color: $white;
    cursor: pointer;

    @media (hover: hover) {
        transition: opacity 0.2s ease-in-out;

        &:hover,
        &:active,
        &:focus {
            opacity: 0.8;
        }
    }
}
