@use 'sass:math';

.t-h1,
%t-h1 {
    font: $fontWeightSemiBold 16px #{'/'} math.div(20, 16) $fontStackSerif;

    @include media('>=tablet') {
        font-size: 24px;
    }
}

.t-h2,
%t-h2 {
    font: $fontWeightSemiBold 14px #{'/'} math.div(20, 16) $fontStackSerif;

    @include media('>=tablet') {
        font-size: 20px;
    }
}

.t-h3,
%t-h3 {
    font: $fontWeightSemiBold 10px #{'/'} math.div(12, 10) $fontStackSerif;

    @include media('>=tablet') {
        font-size: 14px;
    }
}

.t-body,
%t-body {
    font: $fontWeightLight 12px #{'/'} math.div(24, 16) $fontStackSerif;

    @include media('>=tablet') {
        font-size: 16px;
    }
}

.t-small-body,
%t-small-body {
    font: $fontWeightLight 10px #{'/'} math.div(12, 10) $fontStackSerif;

    @include media('>=tablet') {
        font-size: 14px;
    }
}

.t-nav,
%t-nav,
.t-secondary-nav,
%t-secondary-nav {
    font: $fontWeightLight 12px #{'/'} 1 $fontStackSans;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-underline-offset: 3px;
}

.t-secondary-nav,
%t-secondary-nav {
    @include media('<tablet') {
        font-size: 10px;
    }
}

.t-footer,
%t-footer {
    font: $fontWeightLight 10px #{'/'} math.div(16, 10) $fontStackSerif;

    @include media('>=tablet') {
        font-size: 14px;
        line-height: math.div(22, 14);
    }
}

.t-input,
%t-input {
    font: $fontWeightLight 10px #{'/'} math.div(16, 10) $fontStackSerif;

    @include media('>=tablet') {
        font-size: 14px;
    }
}

.t-input-help-text,
%t-input-help-text {
    font: $fontWeightLight 9px #{'/'} math.div(16, 12) $fontStackSerif;

    @include media('>=tablet') {
        font-size: 12px;
    }
}

.t-button,
%t-button {
    font: $fontWeightSemiBold 10px #{'/'} 1 $fontStackSans;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    @include media('>=tablet') {
        font-size: 12px;
    }
}

.t-credits,
%t-credits {
    font: $fontWeightLight 7px #{'/'} 1 $fontStackSans;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-underline-offset: 3px;

    @include media('>=tablet') {
        font-size: 10px;
    }
}
