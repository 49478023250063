.input {
    @extend %t-input;
    display: block;
    width: 100%;
    height: u(2.5);
    padding: u(0.5) 0;

    border: 0;
    border-bottom: 1px solid $black;
    border-radius: 0;
    background: $white;

    appearance: none;
    color: $black;
    transition: all 0.2s ease-in-out;

    &:hover,
    &:focus {
        background: $offWhite;
    }

    &:focus {
        outline: 0;
    }

    @include media('>=tablet') {
        height: u(3.5);
        padding: u(1) 0;
    }
}

// stylelint-disable-next-line selector-no-qualifying-type
textarea.input {
    height: auto;
    resize: vertical;
}
