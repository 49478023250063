.pagination {
    margin-top: u(4);

    @include media('>=tablet') {
        margin-top: u(6);
        width: grid-span($span: 4, $of: 6, $gap: $gridGutterTablet);
    }

    @include media('>=desktop') {
        margin-top: u(6);
        width: grid-span($span: 6, $of: 12, $gap: $gridGutterDesktop);
    }
}

.pagination__list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    gap: u(0.5) u(2);

    // stylelint-disable max-nesting-depth, selector-max-compound-selectors
    .no-flex-gap & > * + * {
        margin-left: u(2);
    }
    // stylelint-enable max-nesting-depth, selector-max-compound-selectors
}

.pagination__item {
    @include media('<tablet') {
        @supports selector(:has(.foo)) {
            &:has(.pagination__title) {
                order: -1;
                width: 100%;
            }
        }
    }
}

.pagination__link {
    @extend %t-secondary-nav;
    @include nav-links;
}


.pagination__title {
    @extend %t-h3;
    line-height: 1;
}
