.category-card {

}

.category-card__link {
    display: flex;
    flex-flow: column;

    text-decoration: none;

    @media (hover: hover) {
        transition: opacity 0.2s ease-in-out;

        &:hover,
        &:active,
        &:focus {
            opacity: 0.7;
        }
    }
}

.category-card__image-wrapper {
    aspect-ratio: 1;
    
    background: $lightestGray;

    border-radius: u(0.25);
}

.category-card__image,
.category-card__image > img {
    display: block;
    width: 100%;
    height: auto;

    border-radius: u(0.25);
}

.category-card__text-wrapper {
    margin-top: u(1);
}

.category-card__title {
    @extend %t-h3;
}
