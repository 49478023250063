.messages {
    &--floating {
        height: 0;
        position: relative;
        z-index: $z-messages;
        top: $pageGutterMobile;
    }

    &:not(&--floating) {
        margin-bottom: u(2);
    }

    @include media('>=tablet') {
        &--floating {
            top: $pageGutterTablet;
        }

        &:not(&--floating) {
            margin-bottom: u(4);
        }
    }
}

.messages__inner {
    @include clamp;
    display: flex;
    flex-flow: column;

    .messages--floating & {
        @include gutter-padding;
    }

    @include media('>=tablet') {
        .messages--floating & {
            align-items: center;
        }
    }
}

.messages__list {
    display: flex;
    flex-flow: column;
    gap: u(2);

    // stylelint-disable max-nesting-depth, selector-max-compound-selectors
    .no-flex-gap & > * + * {
        margin-top: u(2);
    }
    // stylelint-enable max-nesting-depth, selector-max-compound-selectors

    @include media('>=tablet') {
        width: grid-span($span: 5, $of: 6, $gap: $gridGutterTablet);
    }

    @include media('>=desktop') {
        width: grid-span($span: 5, $of: 12, $gap: $gridGutterDesktop);

        .messages--floating & {
            width: grid-span($span: 8, $of: 12, $gap: $gridGutterDesktop);
        }
    }
}

.messages__item {

}

.messages__message {
    @extend %t-body;
    padding: u(1);

    border-radius: u(0.25);

    &.error {
        background: $lightRed;
    }

    &.success {
        background: $green;
    }

    &.info {
        background: $offWhite;
    }

    @include media('>=tablet') {
        padding: u(2);
    }
}
