.u-rich-text {
    // source: https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
    }

    p,
    ul,
    ol {
        margin: 1.125em auto;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    blockquote,
    table,
    img,
    video,
    figure {
        margin: 1.75em auto;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        + p,
        + ul,
        + ol,
        + blockquote,
        + table,
        + img,
        + video,
        + figure {
            margin-top: 0.5em;
        }
    }

    h1 {
        @extend %t-h1;
    }

    h2 {
        @extend %t-h2;
    }

    h3 {
        @extend %t-h3;
    }

    h4 {
        @extend %t-h3;
    }

    h5 {
        @extend %t-h3;
    }

    h6 {
        @extend %t-h3;
    }

    ol,
    ul {
        padding-left: 1em;
    }

    ol {
        list-style-type: decimal;
    }

    ul {
        list-style-type: disc;
    }

    li {
        display: list-item;
        padding-left: 0.5em;
    }

    blockquote {
        border-left: u(0.5) solid $lightGray;
        padding-left: 1em;
    }

    th {
        font-weight: $fontWeightSemiBold;
        text-align: inherit;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    a {
        @include text-links-alt;
    }
}
