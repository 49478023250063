.item-grid {

}

.item-grid__list {
    display: flex;
    flex-flow: row wrap;
    gap: $gridGutterMobile * 1.5 $gridGutterMobile;

    // stylelint-disable max-nesting-depth, selector-max-compound-selectors
    .no-flex-gap & {
        margin: $gridGutterMobile * -0.75 $gridGutterMobile * -0.5;
    }
    // stylelint-enable max-nesting-depth, selector-max-compound-selectors

    @include media('>=tablet') {
        gap: $gridGutterTablet * 1.5 $gridGutterTablet;

        // stylelint-disable max-nesting-depth, selector-max-compound-selectors
        .no-flex-gap & {
            margin: $gridGutterTablet * -0.75 $gridGutterTablet * -0.5;
        }
        // stylelint-enable max-nesting-depth, selector-max-compound-selectors
    }

    @include media('>=desktop') {
        gap: $gridGutterDesktop * 1.5 $gridGutterDesktop;

        // stylelint-disable max-nesting-depth, selector-max-compound-selectors
        .no-flex-gap & {
            margin: $gridGutterDesktop * -0.75 $gridGutterDesktop * -0.5;
        }
        // stylelint-enable max-nesting-depth, selector-max-compound-selectors
    }
}

.item-grid__item {
    width: grid-span($span: 2, $of: 2, $gap: $gridGutterMobile);

    // stylelint-disable max-nesting-depth, selector-max-compound-selectors
    .no-flex-gap & {
        width: grid-span($span: 2, $of: 2, $gap: $gridGutterMobile * 1.5);
        margin: $gridGutterMobile * 0.5;
    }
    // stylelint-enable max-nesting-depth, selector-max-compound-selectors

    @include media('>=tablet') {
        width: grid-span($span: 3, $of: 6, $gap: $gridGutterTablet);

        // stylelint-disable max-nesting-depth, selector-max-compound-selectors
        .no-flex-gap & {
            width: grid-span($span: 3, $of: 6, $gap: $gridGutterTablet * 2);
            margin: $gridGutterTablet * 0.5;
        }
        // stylelint-enable max-nesting-depth, selector-max-compound-selectors
    }

    @include media('>=desktop') {
        width: grid-span($span: 4, $of: 12, $gap: $gridGutterDesktop);

        // stylelint-disable max-nesting-depth, selector-max-compound-selectors
        .no-flex-gap & {
            width: grid-span($span: 4, $of: 12, $gap: $gridGutterDesktop * 1.5);
            margin: $gridGutterDesktop * 0.5;
        }
        // stylelint-enable max-nesting-depth, selector-max-compound-selectors
    }
}
