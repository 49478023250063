.category-pagination {
    margin-top: u(4);

    @include media('>=tablet') {
        margin-top: u(6);
        width: grid-span($span: 3, $of: 6, $gap: $gridGutterTablet);
    }

    @include media('>=desktop') {
        margin-top: u(6);
        width: grid-span($span: 4, $of: 12, $gap: $gridGutterDesktop);
    }
}

.category-pagination__list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    gap: 0 u(2);

    // stylelint-disable max-nesting-depth, selector-max-compound-selectors
    .no-flex-gap & > * + * {
        margin-left: u(2);
    }
    // stylelint-enable max-nesting-depth, selector-max-compound-selectors
}

.category-pagination__item {

}

.category-pagination__link {
    @extend %t-secondary-nav;
    @include nav-links;
}


.category-pagination__title {
    @extend %t-h3;
    line-height: 1;
}
