.item {
    @include grid;
    grid-template-areas:
        '#{str-repeat(2, 'header')}'
        '#{str-repeat(2, 'sidebar')}'
        '#{str-repeat(2, 'main')}';
    row-gap: 0;

    @include media('>=tablet') {
        grid-template-areas:
            '#{str-repeat(5, 'header')}  .'
            '#{str-repeat(5, 'sidebar')} .'
            '#{str-repeat(4, 'main')}  . .';
        row-gap: 0;
    }

    @include media('>=desktop') {
        grid-template-areas:
            '#{str-repeat(5, 'header')} . #{str-repeat(6, 'sidebar')}'
            '#{str-repeat(5, 'main')}   . #{str-repeat(6, 'sidebar')}';
        row-gap: 0;
    }
}

.item__header {
    grid-area: header;
}

.item__main {
    grid-area: main;
}

.item__title {
    @extend %t-h1;

}

.item__subtitle {
    @extend %t-h3;
    margin-top: u(1);

    @include media('>=tablet') {
        margin-top: u(2);
    }
}

.item__description {
    @extend %t-body;
    margin-top: u(2);

    @include media('>=tablet') {
        margin-top: u(4);
    }
}

.item__price {
    @extend %t-h2;
    margin-top: u(2);

    @include media('>=tablet') {
        margin-top: u(4);
    }
}

.item__sharing {
    margin: u(4) 0;
    padding-top: u(4);

    border-top: 1px solid $lighterGray;

    @include media('>=tablet') {
        margin: u(6) 0;
        padding-top: u(6);
    }
}

.item__sharing__heading {
    @extend %t-h3;
}

.item__sharing__list {
    display: flex;
    flex-flow: row wrap;
    gap: u(0.5) u(2);
    margin-top: u(2);

    // stylelint-disable max-nesting-depth, selector-max-compound-selectors
    .no-flex-gap & > * + * {
        margin-left: u(2);
    }
    // stylelint-enable max-nesting-depth, selector-max-compound-selectors

    @include media('>=tablet') {
        gap: u(1) u(4);

        // stylelint-disable max-nesting-depth, selector-max-compound-selectors
        .no-flex-gap & > * + * {
            margin-left: u(4);
        }
        // stylelint-enable max-nesting-depth, selector-max-compound-selectors
    }
}

.item__sharing__item {

}

.item__sharing__link {
    @extend %t-secondary-nav;
    @include nav-links;
    display: inline-flex;
    flex-flow: row;
    gap: u(1);
    align-items: center;

    .icon {
        width: u(2);
        height: u(2);

        color: $darkGray;
    }

    // stylelint-disable max-nesting-depth, selector-max-compound-selectors
    .no-flex-gap & > * + * {
        margin-left: u(1);
    }
    // stylelint-enable max-nesting-depth, selector-max-compound-selectors
}

.item__form {
    margin: u(4) 0;
    padding-top: u(4);

    border-top: 1px solid $lighterGray;

    @include media('>=tablet') {
        margin: u(6) 0;
        padding-top: u(6);
    }
}

.item__sidebar {
    grid-area: sidebar;
    display: flex;
    flex-flow: column;
    gap: u(2);
    margin-top: $gridGutterMobile;

    // stylelint-disable max-nesting-depth, selector-max-compound-selectors
    .no-flex-gap & > * + * {
        margin-top: u(2);
    }
    // stylelint-enable max-nesting-depth, selector-max-compound-selectors

    @include media('>=tablet') {
        margin-top: $gridGutterTablet;
    }

    @include media('>=desktop') {
        margin-top: 0;
    }
}

.item__primary-image {

}

.item__primary-image__link {
    display: block;
    position: relative;

    border-radius: u(0.25);

    overflow: hidden;

    .icon {
        position: absolute;
        inset: u(2) u(2) auto auto;
        width: u(2);
        height: u(2);

        color: $darkGray;
    }

    @media (hover: hover) {
        transition: opacity 0.2s ease-in-out;

        &:hover,
        &:active,
        &:focus {
            opacity: 0.8;
        }
    }
}

.item__primary-image__image,
.item__primary-image__image > img {
    display: block;
    width: 100%;
    height: auto;
}

.item__primary-image__image {
    background: $offWhite;
}

.item__secondary-images {

}

.item__secondary-images__list {
    display: flex;
    flex-flow: row wrap;
    gap: u(2);

    // stylelint-disable max-nesting-depth, selector-max-compound-selectors
    .no-flex-gap & {
        margin: u(-1);
    }
    // stylelint-enable max-nesting-depth, selector-max-compound-selectors
}

.item__secondary-images__item {
    width: grid-span($span: 1, $of: 3, $gap: u(2));

    // stylelint-disable max-nesting-depth, selector-max-compound-selectors
    .no-flex-gap & {
        margin: u(1);
        width: grid-span($span: 1, $of: 3, $gap: u(3));
    }
    // stylelint-enable max-nesting-depth, selector-max-compound-selectors
}

.item__secondary-images__link {
    display: block;

    border-radius: u(0.25);

    overflow: hidden;

    &[aria-current='true'] {
        outline: 1px solid $lightGray;
    }

    @media (hover: hover) {
        transition: opacity 0.2s ease-in-out;

        &:hover,
        &:active,
        &:focus {
            opacity: 0.7;
        }
    }
}

.item__secondary-images__image,
.item__secondary-images__image > img {
    display: block;
    width: 100%;
    height: auto;
}

.item__secondary-images__image {
    background: $offWhite;
}
