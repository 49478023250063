.nav {
    @include media('<desktop') {
        display: none;
        position: absolute;
        inset: 100% 0 auto 0;
        z-index: $z-header;
        max-height: calc(100vh - $headerHeightMobile);

        background: $white;

        box-shadow: 0 u(0.25) u(0.25) rgba($black, 0.1);

        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        overscroll-behavior-y: contain;

        &[aria-expanded='true'] {
            display: block;
        }

        @supports (max-height: 100dvh) {
            max-height: calc(100dvh - $headerHeightMobile);
        }
    }

    @include media('>=tablet', '<desktop') {
        max-height: calc(100vh - $headerHeightTablet);

        @supports (max-height: 100dvh) {
            max-height: calc(100dvh - $headerHeightTablet);
        }
    }
}

.nav__inner {
    @include media('<desktop') {
        @include clamp;
        @include gutter-padding($vertical: true);
    }

    @include media('>=tablet', '<desktop') {
        padding-top: u(2);
    }
}

.nav__primary-list,
.nav__secondary-list {
    display: flex;
    flex-flow: column;
    gap: u(0.75);

    // stylelint-disable max-nesting-depth, selector-max-compound-selectors
    .no-flex-gap & > * + * {
        margin-top: u(0.75);
    }
    // stylelint-enable max-nesting-depth, selector-max-compound-selectors

    @include media('>=desktop') {
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-end;
        gap: u(1) u(3);

        // stylelint-disable max-nesting-depth, selector-max-compound-selectors
        .no-flex-gap & > * + * {
            margin-top: 0;
            margin-left: u(3);
        }
        // stylelint-enable max-nesting-depth, selector-max-compound-selectors
    }

    @include media('>=largeDesktop') {
        gap: u(1) u(4);

        // stylelint-disable max-nesting-depth, selector-max-compound-selectors
        .no-flex-gap & > * + * {
            margin-left: u(4);
        }
        // stylelint-enable max-nesting-depth, selector-max-compound-selectors
    }
}

.nav__secondary-list {
    @include media('<desktop') {
        margin-top: u(0.75);
        padding-left: u(2);
    }

    @include media('>=desktop') {
        display: none;
    }
}

.nav__item {

}

.nav__link {
    @extend %t-nav;
    @include nav-links;
}
