.field {
    display: flex;
    flex-flow: column;
    gap: u(1);

    @include media('>=tablet') {
        flex-flow: row;
        gap: $gridGutterTablet;
    }

    @include media('>=desktop') {
        gap: $gridGutterDesktop;
    }
}

.field__label {
    @extend %t-body;

    // stylelint-disable max-nesting-depth, selector-max-compound-selectors
    .no-flex-gap & {
        margin-right: u(1);
    }
    // stylelint-enable max-nesting-depth, selector-max-compound-selectors

    .field--compact & {
        display: none;
    }

    @include media('>=tablet') {
        width: grid-span($span: 2, $of: 5, $gap: $gridGutterTablet);
        flex-shrink: 0;

        // stylelint-disable max-nesting-depth, selector-max-compound-selectors
        .no-flex-gap & {
            margin-right: $gridGutterTablet;
        }
        // stylelint-enable max-nesting-depth, selector-max-compound-selectors
    }

    @include media('>=desktop') {
        width: grid-span($span: 2, $of: 5, $gap: $gridGutterDesktop);

        // stylelint-disable max-nesting-depth, selector-max-compound-selectors
        .no-flex-gap & > * + * {
            margin-right: $gridGutterDesktop;
        }
        // stylelint-enable max-nesting-depth, selector-max-compound-selectors
    }
}

.field__label__badge {
    font-size: 0.6em;
    color: $red;
}

.field__input-wrapper {
    flex-grow: 1;
}

.field__input {
    .field.has-error & {
        border-bottom-color: $red;

        color: $red;
    }
}

.field__checkbox-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: u(1);

    // stylelint-disable max-nesting-depth, selector-max-compound-selectors
    .no-flex-gap & > * + * {
        margin-left: u(1);
    }
    // stylelint-enable max-nesting-depth, selector-max-compound-selectors

    input {
        flex-shrink: 0;
        width: u(1.5);
        height: u(1.5);
    }
}

.field__help-text {
    @extend %t-input-help-text;

    input:not([type='checkbox']) + & {
        margin-top: u(1);
    }

    .field.has-error .field__checkbox-wrapper & {
        color: $red;
    }

    @include media('>=tablet') {
        a {
            display: inline-block;
        }

        /* stylelint-disable-next-line selector-no-qualifying-type */
        input[type='checkbox'] + & {
            margin-top: u(-0.125);
        }
    }
}

.field__errors {
    @extend %t-input-help-text;
    margin-top: u(1);

    color: $red;
}
