.newsletter-form {

}

.newsletter-form__heading {
    @extend %t-h3;
}

.newsletter-form__copy {
    @extend %t-footer;
}

.newsletter-form__form {
    @extend %t-footer;
    margin-top: u(1);

    @include media('>=tablet') {
        margin-top: u(2);
    }
}
