.home-carousel {
    @include clamp;
}

.home-carousel__inner {
    position: relative;
    z-index: $z-carousel;

    &::after {
        display: block;
        height: 0;
        padding-top: 100%;

        content: '';
    }

    @include media('landscape') {
        height: calc(100vh - $headerHeightMobile);

        &::after {
            display: none;
        }

        @supports (height: 100dvh) {
            height: calc(100dvh - $headerHeightMobile);
        }
    }

    @include media('>=tablet', 'landscape') {
        height: calc(100vh - $headerHeightTablet);

        @supports (height: 100dvh) {
            height: calc(100dvh - $headerHeightTablet);
        }
    }

    @include media('>=desktop', 'landscape') {
        min-height: u(50);
        height: calc(100vh - $headerHeightDesktop);

        @supports (height: 100dvh) {
            height: calc(100dvh - $headerHeightDesktop);
        }
    }

    @include media('>=#{$layoutMaxWidth}', 'landscape') {
        @supports (aspect-ratio: 16 / 9) {
            max-height: calc($layoutMaxWidth / 16 * 9);

            &::after {
                display: none;
            }
        }
    }
}

.home-carousel__slide {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;

    transition: opacity 2s ease-in-out;
    opacity: 0;

    &.is-visible {
        opacity: 1;
    }
}

.home-carousel__image,
.home-carousel__image > img {
    display: block;
    width: 100%;
    height: 100%;

    object-fit: cover;
}
