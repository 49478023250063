.l-container {
    position: relative;
    margin: 0 auto;
    padding: $pageGutterMobile $pageGutterMobile $pageGutterMobile * 1.5 $pageGutterMobile;
    max-width: $layoutMaxWidth;

    @include media('>=tablet') {
        padding: $pageGutterTablet $pageGutterTablet $pageGutterTablet * 1.5 $pageGutterTablet;
    }

    @include media('>=desktop') {
        padding: $pageGutterDesktop $pageGutterDesktop $pageGutterDesktop * 1.5 $pageGutterDesktop;
    }
}
