.form {

}

.form__header {
    margin-bottom: u(1);

    @include media('>=tablet') {
        margin-bottom: u(2);
    }
}

.form__title {
    @extend %t-h3;
}

.form__main {
    display: flex;
    flex-flow: column;
    gap: u(2);

    // stylelint-disable max-nesting-depth, selector-max-compound-selectors
    .no-flex-gap & > .field + .field {
        margin-top: u(2);
    }
    // stylelint-enable max-nesting-depth, selector-max-compound-selectors
}

.form__errors {
    @extend %t-input-help-text;

    color: $red;
}

.form__footer {
    margin-top: u(1);
    display: flex;
    flex-flow: row;
    justify-content: center;

    @include media('>=tablet') {
        margin-top: u(3);

        .form--compact & {
            margin-top: u(2);
        }
    }
}

.form__button {
    width: 100%;

    @include media('>=tablet') {
        max-width: grid-span($span: 3, $of: 5, $gap: $gridGutterTablet);

        .form--compact & {
            max-width: 100%;
        }
    }

    @include media('>=desktop') {
        max-width: grid-span($span: 3, $of: 5, $gap: $gridGutterDesktop);

        .form--compact & {
            max-width: 100%;
        }
    }
}
