.newsletter-toast-wrapper {
    position: sticky;
    bottom: 0;
    height: 0;
    z-index: $z-toast;
}

.newsletter-toast-wrapper__inner {
    @include clamp;
    @include gutter-padding;
    position: absolute;
    inset: auto 0 0 0;
}

.newsletter-toast {
    @include media('<tablet') {
        display: none;
    }

    @include media('>=tablet') {
        position: relative;
        width: grid-span($span: 3, $of: 6, $gap: $gridGutterTablet);
        padding: u(2);
        left: u(-2);

        background: $white;

        box-shadow: 0 u(-0.25) u(0.5) rgba($black, 0.1);

        border-top-left-radius : u(0.25);
        border-top-right-radius : u(0.25);

        opacity: 0;
        pointer-events: none;
        transition: opacity 0.5s ease-in-out;

        &.is-open {
            opacity: 1;
            pointer-events: all;
        }
    }

    @include media('>=desktop') {
        width: grid-span($span: 4, $of: 12, $gap: $gridGutterDesktop, $delta: u(4));
    }
}

.newsletter-toast__close {
    display: block;
    width: u(2);
    height: u(2);
    position: absolute;
    inset: u(2) u(2) auto auto;

    .icon {
        display: block;
        width: 100%;
        height: 100%;
    }
}
