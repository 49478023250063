.header {
    background: $white;

    @include media('<desktop') {
        position: sticky;
        top: 0;
        z-index: $z-header;
    }
}

.header__inner {
    @include clamp;
    @include gutter-padding;

    position: relative;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    height: u(5.5);
    padding-top: u(1);
    padding-bottom: u(1);

    @include media('<desktop') {
        > * {
            flex: 1 1 0;
        }
    }

    @include media('>=tablet') {
        height: u(7.5);
        padding-top: u(2);
        padding-bottom: u(2);
    }

    @include media('>=desktop') {
        flex-flow: row;
        gap: u(6);
        height: u(11);
    }
}

.header__primary-actions,
.header__secondary-actions {
    @include media('>=desktop') {
        display: none;
    }
}

.header__nav-toggle {
    display: block;
    width: u(3);
    height: u(3);

    .icon {
        width: u(3);
        height: u(3);
    }

    .icon--close {
        display: none;
    }

    &[aria-expanded='true'] {
        .icon--menu {
            display: none;
        }

        .icon--close {
            display: block;
        }
    }
}

.header__logo {
    display: flex;
    flex-flow: row;
    justify-content: center;

    @include media('>=desktop') {
        // stylelint-disable max-nesting-depth, selector-max-compound-selectors
        .no-flex-gap & {
            margin-right: u(6);
        }
        // stylelint-enable max-nesting-depth, selector-max-compound-selectors
    }
}

.header__logo__link {
    @include hide-text-alt;
    display: block;
    width: 100px;
    height: 27px;

    background: url('../svg/logo.svg') 0 0 no-repeat;
    background-size: contain;

    @media (hover: hover) {
        transition: opacity 0.2s ease-in-out;

        &:hover,
        &:active,
        &:focus {
            opacity: 0.7;
        }
    }

    @include media('>=desktop') {
        width: 200px;
        height: 54px;
    }
}

.header__spacer {
    @include media('>=desktop') {
        display: none;
    }
}
