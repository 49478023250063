.category-nav {
    margin-bottom: u(2);

    @include media('>=tablet') {
        margin-bottom: u(4);
    }
}

.category-nav__list {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 0 u(2);

    // stylelint-disable max-nesting-depth, selector-max-compound-selectors
    .no-flex-gap & > * + * {
        margin-left: u(2);
    }
    // stylelint-enable max-nesting-depth, selector-max-compound-selectors

    @include media('>=tablet') {
        gap: u(1) u(4);

        // stylelint-disable max-nesting-depth, selector-max-compound-selectors
        .no-flex-gap & > * + * {
            margin-left: u(4);
        }
        // stylelint-enable max-nesting-depth, selector-max-compound-selectors
    }
}

.category-nav__item {

}

.category-nav__link {
    @extend %t-secondary-nav;
    @include nav-links;
}
