.footer {
    @extend %t-footer;

    background: $white;
}

.footer__inner {
    @include clamp;
    @include gutter-padding($vertical: true);
    @include grid;
    row-gap: 0;
    grid-template-areas:
        'one     two    '
        'three   three  '
        'credits credits';

    @include media('>=tablet') {
        row-gap: 0;
        grid-template-areas:
            '#{str-repeat(3, 'one')} #{str-repeat(3, 'three')}'
            '#{str-repeat(3, 'two')} #{str-repeat(3, 'three')}'
            '#{str-repeat(6, 'credits')}';
    }

    @include media('>=desktop') {
        row-gap: 0;
        grid-template-areas:
            '#{str-repeat(3, 'one')} #{str-repeat(3, 'two')} #{str-repeat(2, '\.')} #{str-repeat(4, 'three')}'
            '#{str-repeat(6, 'credits')} #{str-repeat(2, '\.')} #{str-repeat(4, 'three')}';
        padding-top: u(4);
        padding-bottom: u(5);
    }
}

.footer__column {
    &:nth-child(1) {
        grid-area: one;
    }

    &:nth-child(2) {
        grid-area: two;
    }

    &:nth-child(3) {
        grid-area: three;
        margin-top: u(3);
    }

    @include media('>=tablet') {
        &:nth-child(2) {
            margin-top: u(3);
        }

        &:nth-child(3) {
            margin-top: 0;
        }
    }

    @include media('>=desktop') {
        &:nth-child(2) {
            margin-top: 0;
        }
    }
}

.footer__heading {
    @extend %t-h3;

    &:not(:first-child) {
        margin-top: u(2);
    }
}

.footer__copy {
    & + & {
        margin-top: u(2);
    }
}

.footer__credits {
    @extend %t-credits;
    grid-area: credits;
    margin-top: u(5);
    display: flex;
    flex-flow: row wrap;
    gap: u(1);

    color: $lightGray;

    // stylelint-disable max-nesting-depth, selector-max-compound-selectors
    .no-flex-gap & > * + * {
        margin-left: u(1);
    }
    // stylelint-enable max-nesting-depth, selector-max-compound-selectors

    @include media('>=tablet') {
        margin-top: u(8);
        gap: u(2);

        // stylelint-disable max-nesting-depth, selector-max-compound-selectors
        .no-flex-gap & > * + * {
            margin-left: u(2);
        }
        // stylelint-enable max-nesting-depth, selector-max-compound-selectors
    }
}

.footer__credits__item {
    display: inline-block;
}

.footer__credits__separator {
    &::after {
        content: '\00B7';
    }
}
