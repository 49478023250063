html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    position: relative;
    margin: 0;
    padding: 0;

    background: $white;

    font: normal $fontWeightLight $baseFontSize #{'/'} $baseLineHeightRatio $fontStackSerif;
    color: $black;
    -webkit-font-smoothing: antialiased;
}


// Additional resets on top of minireset.css /////////////////////////////////
input {
    padding: 0;
    font: inherit;
    border: 0;
}

// stylelint-disable-next-line selector-no-qualifying-type
input[type='text'],
textarea {
    appearance: none;

    &:disabled {
        background-color: initial;
    }
}

a {
    color: inherit;
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}

strong {
    font-weight: $fontWeightSemiBold
}

button {
    padding: 0;

    border: 0;
    border-radius: 0;

    background: transparent;

    cursor: pointer;
    font: inherit;
    color: inherit;
    text-align: inherit;
    appearance: none;

    &:disabled {
        cursor: default;
    }
}

// venobox overrides //////////////////////////////////////////////////////////
.vbox-figlio {
    width: 100%;
    box-shadow: none;

    @include media('>desktop') {
        max-width: 800px;
    }
}

// recaptcha overrides ////////////////////////////////////////////////////////
@include media('<tablet') {
    .g-recaptcha {
        margin-top: 5px;
        transform: scale(0.925);
        transform-origin: top left;
    }
}
